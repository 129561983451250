<template>
  <section
    id="ViewFile"
    class="m-2"
  >
    <b-row v-if="!updateFile">
      <b-col
        :cols="subtitle ? '6' : '12'"
      >
        <h3>{{ datos.name }}</h3>
        <h5 class="text-primary">
          {{ $t("media.previewFile") }}
        </h5>
      </b-col>
    </b-row>
    <b-row
      v-if="updateFile"
      class="mb-1 align-items-center"
    >
      <b-col>
        <validation-observer
          ref="rulesFile"
          tag="form"
        >
          <b-row class="form-group">
            <b-col class="d-flex justify-content-between pl-0 pr-0 mb-1">
              <b-form-group style="flex-grow: 1;">
                <validation-provider
                  v-slot="validationContext"
                  name="Name"
                  :rules="{
                    required: true,
                    min: 3,
                    regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
                  }"
                >
                  <b-form-input
                    v-model="updateData.name"
                    :state="validationContext.errors.length > 0 ? false : null"
                    :placeholder="$t('resources.tableHeader.name')"
                    maxlength="150"
                  />
                  <small class="text-danger">{{ fix(validationContext) }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="success"
                size="md"
                class="ml-1 text-nowrap"
                style="height: fit-content;"
                @click.stop="event => validationForm()"
              >
                {{ $t('dataGeneric.save') }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <h5 class="text-primary">
            {{ $t("media.previewFile") }}
          </h5>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="!subtitle">
      <b-col class="embed-responsive embed-responsive-16by9">
        <video
          v-if="vid"
          id="file"
          class="
            video-js
            vjs-default-skin vjs-big-play-centered
            file-wigth
            embed-responsive-item reel-dimension
          "
          controls
          data-setup="{&quot;liveui&quot;: true}"
          :poster="aud
            ? require('@/assets/images/icons/audio.svg')
            : ''
          "
          @loadeddata="videoMaker()"
          @loadstart="videoMaker()"
        >
          <source
            :src="datos.fileUrl"
            :type="datos.fileUrl.includes('m3u8')
              ? 'application/x-mpegURL'
              : datos.fileUrl.includes('mp4')
                ? 'video/mp4'
                : 'audio/mp3'
            "
          >
        </video>
        <img
          v-if="img"
          class="embed-responsive-item"
          style="object-fit: contain;"
          :src="datos.fileUrl"
          alt="Imagen archivo"
        >
      </b-col>
    </b-row>
    <b-row
      v-else
      id="rowVideo"
    >
      <b-col
        class="embed-responsive embed-responsive-16by9"
        cols="6"
      >
        <video
          v-if="vid"
          id="file"
          class="
            video-js
            vjs-default-skin vjs-big-play-centered
            file-wigth
            embed-responsive-item
          "
          controls
          data-setup="{&quot;liveui&quot;: true}"
          :poster="aud
            ? require('@/assets/images/icons/audio.svg')
            : ''
          "
          @loadeddata="videoMaker()"
          @loadstart="videoMaker()"
        >
          <source
            :src="datos.fileUrl"
            :type="datos.fileUrl.includes('m3u8')
              ? 'application/x-mpegURL'
              : datos.fileUrl.includes('mp4')
                ? 'video/mp4'
                : 'audio/mp3'
            "
          >
          <!-- TODO
          <track
            v-for="(language, index) in subtitlesLang"
            :key="index"
            kind="captions"
            :src="language.src"
            :srclang="language.srcLang"
            :label="language.label"
          > -->
        </video>

        <img
          v-if="img"
          class="embed-responsive-item"
          style="object-fit: contain;"
          :src="datos.fileUrl"
          alt="Imagen archivo"
        >
      </b-col>
      <b-button
        v-if="vid"
        class="ml-2 h-25 py-1"
        variant="primary"
        @click="showModal('subtitles')"
      >
        {{ $t('subtitles.subtitlesEditor') }}
      </b-button>
    </b-row>
    <b-overlay
      :show="show"
      no-wrap
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-4 "
        >
          <!--b-icon icon="cloud-upload" font-scale="4"></b-icon-->
          <feather-icon
            style="color:white"
            icon="UploadIcon"
            size="75"
          />
          <h1 style="color:white">
            Upload...
          </h1>
          <b-progress
            min="0"
            :max="100"
            :value="progress"
            variant="light"
            height="11px"
            class="mx-n4 rounded-0"
          />
        </div>
      </template>
    </b-overlay>
    <b-modal
      id="subtitle-modal"
      ref="subtitleModal"
      hide-footer
      :size="modalSize"
      :title="$t('subtitles.subtitlesEditor')"
      @hide="resizeModal('lg')"
    >
      <subtitles
        :archivo-url-preview="datos.fileUrl"
        @resizeModal="resizeModal($event)"
        @hideModal="hideModal('subtitles')"
      />
    </b-modal>
  </section>
</template>

<script>
import { Video } from 'video-metadata-thumbnails'

import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormGroup,
  BModal,
  BOverlay,
  BProgress,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import Subtitles from './Subtitles.vue'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    BModal,
    BProgress,
    Subtitles,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    datos: {
      type: Object,
      default() {
        return {}
      },
    },
    updateFile: {
      type: Boolean,
      default: false,
    },
    uploadData: {
      type: Object,
      default() {
        return {}
      },
    },
    progress: {
      type: Number,
      default: 0,
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
    calculateDuration: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      video: false,
      show: false,
      fileUrl: null,
      selected: null,
      cardmoveId: [],
      cardmoveOrder: [],
      orderOrig: null,
      videojs: null,
      idOrig: null,
      folders: null,
      currentParentFolder: null,
      duration: 0,
      valueUpload: 0,
      processing: null,
      vid: false,
      aud: false,
      img: false,
      subtitlesLang: [],
      modalSize: 'lg',
      required,
    }
  },
  computed: {
    updateData() {
      return this.datos
    },
  },
  created() {
    // TODO this.setSubtitles()
  },
  mounted() {
    if (this.updateFile) {
      if (this.uploadData.file.type.includes('video') || this.uploadData.file.type.includes('audio')) {
        this.vid = true
        this.aud = this.uploadData.file.type.includes('audio')
      }
      this.img = this.uploadData.file.type.includes('image')
    } else if (this.datos.fileUrl.includes('jpg') || this.datos.fileUrl.includes('png') || this.datos.fileUrl.includes('jpeg')) {
      this.img = true
    } else {
      this.vid = true
      if (this.datos.fileUrl.includes('mp3')) {
        this.aud = true
      }
    }
    if (!this.datos.fileUrl.includes('jpg') && !this.datos.fileUrl.includes('png') && !this.datos.fileUrl.includes('jpeg') && this.calculateDuration) {
      this.setDuration()
    }
  },
  beforeDestroy() {
    if (this.videojs) this.videojs.dispose()
  },
  methods: {
    setDuration() {
      try {
        const video = new Video(this.uploadData.file)
        video
          .getMetadata()
          .then(metadata => {
            console.log(metadata)

            this.duration = metadata.duration
            console.log(metadata)
          })
          .catch(error => {
            // eslint-disable-next-line
            console.error('Error al obtener metadata del video:', error);
          })
      } catch (error) {
        // console.log(error)
      }
    },
    loading() {
      this.processing = false
      this.show = true
      this.$swal({
        title: this.$t('code.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.onOK()
        } else { this.onCancel() }
      }).catch(() => {

      })
    },
    onCancel() {
      this.show = false
    },
    onOK() {
      this.$emit('update', this.duration, this.updateData.name)
      this.processing = true
    },
    videoMaker() {
      try {
        this.videojs = videojs('file')
        this.videojs.on('ready', () => {
          try {
            this.videojs.vhs = null
            // eslint-disable-next-line no-empty
          } catch (e) { }
        })
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },
    setSubtitles() {
      axios
        .post('', {
          query: `
            query ($resource: ID) {
              allSubtitles (resource: $resource) {
                edges {
                  node {
                    id
                    language
                    url
                  }
                }
              }
            }
          `,
          variables: {
            resource: this.datos.id,
          },
        })
        .then(response => {
          messageError(response, this)
          if (response.data.data.allSubtitles.edges.length > 0) {
            const data = response.data.data.allSubtitles.edges
            data.node.forEach(element => {
              this.subtitlesLang.push({
                src: element.url,
                srcLang: element.language,
                label: this.$t(element.language),
              })
            })
          }
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    showModal(type) {
      switch (type) {
        case 'subtitles':
          this.$refs.subtitleModal.show()
          break
        default:
          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'subtitles':
          this.$refs.subtitleModal.hide()
          break
        default:
          break
      }
    },
    fix(data) {
      let message = ''
      const keys = Object.keys(data.failedRules)

      if (keys.includes('required')) {
        message = this.$t('purchases.nameFormValid')
      }
      if (keys.includes('min')) {
        message = this.$t('purchases.nameFormThreeCaract')
      }

      return message
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.rulesFile.validate().then(success => {
          if (success) {
            resolve(true)
            this.loading()
          } else {
            reject()
          }
        })
      })
    },
    resizeModal(size) {
      this.modalSize = size
    },
  },
}
</script>
<style>
.file-wigth {
  max-width: 100%;
  min-width: 100%;
}

.reel-dimension {
  display: contents !important;
}

.modal-body {
  height: fit-content;
}
</style>
