var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"m-2",attrs:{"id":"ViewFile"}},[(!_vm.updateFile)?_c('b-row',[_c('b-col',{attrs:{"cols":_vm.subtitle ? '6' : '12'}},[_c('h3',[_vm._v(_vm._s(_vm.datos.name))]),_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t("media.previewFile"))+" ")])])],1):_vm._e(),(_vm.updateFile)?_c('b-row',{staticClass:"mb-1 align-items-center"},[_c('b-col',[_c('validation-observer',{ref:"rulesFile",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"form-group"},[_c('b-col',{staticClass:"d-flex justify-content-between pl-0 pr-0 mb-1"},[_c('b-form-group',{staticStyle:{"flex-grow":"1"}},[_c('validation-provider',{attrs:{"name":"Name","rules":{
                  required: true,
                  min: 3,
                  regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
                }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-input',{attrs:{"state":validationContext.errors.length > 0 ? false : null,"placeholder":_vm.$t('resources.tableHeader.name'),"maxlength":"150"},model:{value:(_vm.updateData.name),callback:function ($$v) {_vm.$set(_vm.updateData, "name", $$v)},expression:"updateData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fix(validationContext)))])]}}],null,false,2930564227)})],1),_c('b-button',{staticClass:"ml-1 text-nowrap",staticStyle:{"height":"fit-content"},attrs:{"variant":"success","size":"md"},on:{"click":function($event){$event.stopPropagation();return (function (event) { return _vm.validationForm(); })($event)}}},[_vm._v(" "+_vm._s(_vm.$t('dataGeneric.save'))+" ")])],1)],1)],1),_c('b-row',[_c('h5',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.$t("media.previewFile"))+" ")])])],1)],1):_vm._e(),(!_vm.subtitle)?_c('b-row',[_c('b-col',{staticClass:"embed-responsive embed-responsive-16by9"},[(_vm.vid)?_c('video',{staticClass:"\n          video-js\n          vjs-default-skin vjs-big-play-centered\n          file-wigth\n          embed-responsive-item reel-dimension\n        ",attrs:{"id":"file","controls":"","data-setup":"{\"liveui\": true}","poster":_vm.aud
          ? require('@/assets/images/icons/audio.svg')
          : ''},on:{"loadeddata":function($event){return _vm.videoMaker()},"loadstart":function($event){return _vm.videoMaker()}}},[_c('source',{attrs:{"src":_vm.datos.fileUrl,"type":_vm.datos.fileUrl.includes('m3u8')
            ? 'application/x-mpegURL'
            : _vm.datos.fileUrl.includes('mp4')
              ? 'video/mp4'
              : 'audio/mp3'}})]):_vm._e(),(_vm.img)?_c('img',{staticClass:"embed-responsive-item",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.datos.fileUrl,"alt":"Imagen archivo"}}):_vm._e()])],1):_c('b-row',{attrs:{"id":"rowVideo"}},[_c('b-col',{staticClass:"embed-responsive embed-responsive-16by9",attrs:{"cols":"6"}},[(_vm.vid)?_c('video',{staticClass:"\n          video-js\n          vjs-default-skin vjs-big-play-centered\n          file-wigth\n          embed-responsive-item\n        ",attrs:{"id":"file","controls":"","data-setup":"{\"liveui\": true}","poster":_vm.aud
          ? require('@/assets/images/icons/audio.svg')
          : ''},on:{"loadeddata":function($event){return _vm.videoMaker()},"loadstart":function($event){return _vm.videoMaker()}}},[_c('source',{attrs:{"src":_vm.datos.fileUrl,"type":_vm.datos.fileUrl.includes('m3u8')
            ? 'application/x-mpegURL'
            : _vm.datos.fileUrl.includes('mp4')
              ? 'video/mp4'
              : 'audio/mp3'}})]):_vm._e(),(_vm.img)?_c('img',{staticClass:"embed-responsive-item",staticStyle:{"object-fit":"contain"},attrs:{"src":_vm.datos.fileUrl,"alt":"Imagen archivo"}}):_vm._e()]),(_vm.vid)?_c('b-button',{staticClass:"ml-2 h-25 py-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showModal('subtitles')}}},[_vm._v(" "+_vm._s(_vm.$t('subtitles.subtitlesEditor'))+" ")]):_vm._e()],1),_c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":"","variant":"transparent","opacity":1.00,"blur":'2px',"rounded":"lg"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [(_vm.processing)?_c('div',{staticClass:"text-center p-4 "},[_c('feather-icon',{staticStyle:{"color":"white"},attrs:{"icon":"UploadIcon","size":"75"}}),_c('h1',{staticStyle:{"color":"white"}},[_vm._v(" Upload... ")]),_c('b-progress',{staticClass:"mx-n4 rounded-0",attrs:{"min":"0","max":100,"value":_vm.progress,"variant":"light","height":"11px"}})],1):_vm._e()]},proxy:true}])}),_c('b-modal',{ref:"subtitleModal",attrs:{"id":"subtitle-modal","hide-footer":"","size":_vm.modalSize,"title":_vm.$t('subtitles.subtitlesEditor')},on:{"hide":function($event){return _vm.resizeModal('lg')}}},[_c('subtitles',{attrs:{"archivo-url-preview":_vm.datos.fileUrl},on:{"resizeModal":function($event){return _vm.resizeModal($event)},"hideModal":function($event){return _vm.hideModal('subtitles')}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }