<template>
  <section id="reelCards">
    <b-form-checkbox-group
      id="checkbox-group-1"
      v-model="selectContent"
    >
      <b-overlay
        variant="transparent"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <!-- RESULTS AMOUNT -->
        <b-row>
          <b-col>
            <span v-if="files && files.length != 0">{{ $t('reels.reelsShown', { length: files.length, totalReels: totalReels }) }}</span>
            <span v-else>{{ $t('resultNotFound') }}</span>
          </b-col>
        </b-row>
        <!-- CARDS -->
        <b-row>
          <b-col
            v-for="reel in files"
            :key="reel.node.key"
            class="mt-1"
            md="6"
            lg="2"
            xl="2"
            sm="12"
          >
            <b-card
              :id="`reel-${reel.node.name}`"
              class="cardContent"
              no-body
              @click.stop.prevent="choice == true
                ? clickCheck(reel.node)
                : $emit('showModal', 'playReelModal', reel.node)
              "
            >
              <!-- THUMBNAIL -->
              <video
                id="demo"
                class="video-js vjs-default-skin vjs-big-play-centered cursor-pointer"
                data-setup="{&quot;liveui&quot;: true}"
                preload="metadata"
                :src="reel.node.fileUrl"
              />
              <!-- CARD CONTENT -->
              <b-card-header>
                <div id="acortarPadre">
                  <b-card-title
                    id="acortar"
                    class="mb-25"
                  >
                    <span
                      v-if="reel.node.isActive"
                      class="mr-50 bullet bullet-success bullet-sm"
                    />
                    <span
                      v-else
                      class="mr-50 bullet bullet-danger bullet-sm"
                    />
                    {{ reel.node.name }}
                  </b-card-title>
                  <b-card-text>
                    <p
                      v-if="reel.node.description"
                      class="description-style mb-0"
                    >
                      {{ reel.node.description }}
                    </p>
                    <span v-else>
                      <br>
                    </span>
                  </b-card-text>
                </div>
              </b-card-header>
              <!-- CARD HEADER -->
              <b-card-body>
                <div
                  v-if="choice != true"
                  class="actions d-flex justify-content-between"
                >
                  <b-badge
                    v-b-tooltip.hover.top.v-primary="$t('reels.desactivateReel')"
                    class="cursor-pointer ml-1"
                    :variant="reel.node.isActive ? 'success' : 'danger'"
                    @click.stop.prevent="$emit('updateReelStatus', reel.node)"
                  >
                    {{ reel.node.isActive ? $t('dataGeneric.active') : $t('dataGeneric.inactive') }}
                  </b-badge>
                  <!-- DROPDOWN OPTIONS -->
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="17"
                        class="align-middle text-body"
                      />
                    </template>
                    <!-- PLAY REEL -->
                    <b-dropdown-item
                      @click.stop="$emit('showModal', 'playReelModal', reel.node)"
                    >
                      <div class="d-flex">
                        <feather-icon
                          icon="EyeIcon"
                          class="text-success mr-2"
                          size="18"
                        />
                        {{ $t('see') }}
                      </div>
                    </b-dropdown-item>
                    <!-- EDIT REEL -->
                    <b-dropdown-item
                      @click.stop="$emit('showModal', 'editReelModal', reel.node)"
                    >
                      <div class="d-flex">
                        <feather-icon
                          icon="Edit2Icon"
                          class="text-warning mr-2"
                          size="18"
                        />
                        {{ $t('dataGeneric.edit') }}
                      </div>
                    </b-dropdown-item>
                    <!-- DOWNLOAD REEL -->
                    <b-dropdown-item
                      @click.stop="$emit('downloadReel', reel.node)"
                    >
                      <div class="d-flex">
                        <feather-icon
                          icon="DownloadIcon"
                          class="text-info mr-2"
                          size="18"
                        />
                        {{ $t('download') }}
                      </div>
                    </b-dropdown-item>
                    <!-- DELETE REEL -->
                    <b-dropdown-item
                      @click.stop="$emit('deleteReel', reel.node.id)"
                    >
                      <div class="d-flex">
                        <feather-icon
                          icon="Trash2Icon"
                          class="text-danger mr-2"
                          size="18"
                        />
                        {{ $t('dataGeneric.delete') }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div
                  v-else
                  class="actions right"
                >
                  <div
                    @click.stop.prevent="clickCheck(reel.node)"
                  >
                    <b-form-checkbox
                      class="ml-2"
                      disabled
                      :value="reel.node.id"
                    />
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-overlay>
    </b-form-checkbox-group>
  </section>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'

export default {
  components: {
    BBadge,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BOverlay,
    BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    reels: {
      type: Array,
      default: () => [],
    },
    all: {
      type: Boolean,
      default: false,
    },
    choice: {
      type: Boolean,
      default: false,
    },
    totalReels: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      files: null,
      infoContent: [],
      selectContent: [],
    }
  },
  watch: {
    reels(newValue) {
      this.files = newValue
    },
    choice() {
      if (!this.choice) {
        this.selectContent = []
      }
      this.infoContent = []
    },
    all() {
      if (this.all) {
        this.selectContent = []
        this.infoContent = []
        this.files.forEach(element => {
          this.clickCheck(element.node, true)
        })
      } else {
        this.selectContent = []
        this.infoContent = []
        this.$emit('setChosenReels', this.selectContent)
      }
    },
  },
  created() {
    this.files = this.reels.map(element => {
      const key = Math.random().toString(36).substring(7)
      return { ...element, ...key }
    })
  },
  methods: {
    clickCheck(node, type = null) {
      const i = this.selectContent.indexOf(node.id)
      const a = this.infoContent.map(e => e.id).indexOf(node.id)
      if (type === null) {
        if (i !== -1) {
          this.selectContent.splice(i, 1)
          this.infoContent.splice(a, 1)
        } else {
          this.selectContent.push(node.id)
          this.infoContent.push(node)
        }
        this.$emit('setChosenReels', this.selectContent)
      } else if (i !== -1) {
        this.selectContent.splice(i, 1)
        this.infoContent.splice(a, 1)
      } else {
        this.selectContent.push(node.id)
        this.infoContent.push(node)
        this.$emit('setChosenReels', this.selectContent)
      }
    },
  },
}
</script>

<style scoped>
.description-style {
  text-overflow: ellipsis;
  overflow: hidden;
}

#reelCards .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#reelCards .cardContent video {
  height: 11vw;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#reelCards .card-body {
  padding: 1rem;
}

#reelCards .card-body h4 {
  font-size: 1rem !important;
}

#reelCards .card-header {
  padding: 1rem;
}

#reelCards .card-header h4 {
  font-size: 1rem !important;
}

#reelCards .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 100; */
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  /* justify-content: flex-end; */
  justify-content: space-between;
}

#reelCards .dropContainer {
  border: 3px dashed;
}

#reelCards .list-group-item {
  transition: all 1s;
}

#reelCards #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#reelCards #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

</style>
