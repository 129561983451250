<template>
  <section id="reels">
    <h1>
      {{ $t("reels.reels") }}
    </h1>
    <h5 class="text-primary">
      {{ $t("reels.reelsSubtitle") }}
    </h5>

    <b-row class="mb-2">
      <!-- SEARCHBAR -->
      <b-col
        cols="6"
        class="d-flex"
      >
        <b-form-input
          v-model="search"
          :placeholder="$t('Search')"
          @keyup.enter="searching()"
        />
        <b-button
          class="ml-1 text-nowrap"
          variant="primary"
          @click="searching()"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
      <!-- ADD BUTTON -->
      <b-col
        cols="6"
        class="d-flex justify-content-end"
      >
        <b-button
          variant="primary"
          class="text-nowrap"
          @click="redirectAddReel"
          @downloadReel="downloadReel"
          @showModal="showModal"
          @updateReelStatus="updateReelStatus"
        >
          {{ $t('reels.addReel') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- MULTI SELECT -->
    <b-row>
      <!-- MULTI SELECT CHECKBOX -->
      <b-col>
        <b-form-checkbox
          v-model="select"
          switch
          :disabled="searchFiles.length == 0 ? true : false"
        >
          {{ $t('reels.multipleSelection') }}
        </b-form-checkbox>
      </b-col>
      <!-- SELECT ALL -->
      <b-col v-if="select">
        <b-form-checkbox
          v-model="all"
          switch
        >
          {{ $t('slider.allSelect') }}
        </b-form-checkbox>
      </b-col>
      <!-- SELECT STATUS -->
      <b-col
        v-if="select"
        cols="2"
      >
        <b-form-select
          v-model="state"
          :options="optionsStatus"
        />
      </b-col>
      <!-- APPLY NEW STATUS BUTTON -->
      <b-col
        v-if="select"
        cols="2"
      >
        <b-button
          v-if="chosenReels.length > 0 && state != null"
          variant="primary"
          @click.stop.prevent="updateReelStatusMultiple()"
        >
          {{ $t('dataGeneric.change') }}
        </b-button>
        <b-button
          v-else
          disabled
        >
          {{ $t('dataGeneric.change') }}
        </b-button>
      </b-col>
      <b-col
        v-if="select"
        class="d-flex justify-content-end"
      >
        <b-button
          :disabled="chosenReels.length > 0 ? false : true"
          variant="danger"
          @click="deleteMultiple()"
        >
          {{ $t('dataGeneric.delete') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- REELS TABS -->
    <b-row>
      <b-col class="mt-2">
        <reels-list-card
          v-if="searchFiles.length > 0"
          :reels="searchFiles"
          :all="all"
          :choice="select"
          :total-reels="nReel"
          @deleteReel="askDeleteReel"
          @downloadReel="downloadReel"
          @setChosenReels="setChosenReels"
          @showModal="showModal"
          @updateReelStatus="updateReelStatus"
        />
        <!-- LOAD MORE MESSAGE -->
        <div
          v-if="hasMore"
          class="d-flex justify-content-center align-self-center mt-2"
        >
          <small>{{ $t('downToSee') }}</small><feather-icon
            icon="ArrowDownIcon"
            size="20"
            class="ml-50"
          />
        </div>
        <div ref="infiniteScroll" />
      </b-col>
    </b-row>
    <!-- PLAY REEL MODAL-->
    <b-modal
      ref="playReelModal"
      size="lg"
      hide-footer
      :title="$t('reels.viewReel')"
    >
      <div class="d-block text-center">
        <view-file-modal
          :datos="playReel"
          :calculate-duration="false"
        />
      </div>
    </b-modal>
    <!-- EDIT REEL MODAL -->
    <b-modal
      v-if="playReel"
      ref="editReelModal"
      size="custom"
      hide-footer
      :title="$t('reels.editReel')"
    >
      <div class="d-flex">
        <b-col cols="8">
          <b-tabs
            v-if="languageService"
            pills
            lazy
            vertical
          >
            <b-tab
              v-for="i in locales"
              :key="i.locale"
            >
              <!-- LANGUAGES SELECTION -->
              <template #title>
                <div
                  class="d-flex"
                  style="flex:auto"
                >
                  <b-img
                    :src="i.img"
                    height="14px"
                    width="22px"
                    :alt="i.locale"
                  />
                  <strong class="ml-50">{{ i.name }}</strong>
                </div>
              </template>
              <!-- EDIT REEL FORM -->
              <reels-edit
                :language="i.locale"
                :base="base"
                :original-object="playReel"
                @editUploadReel="editUploadReel"
                @hideModal="hideModal"
                @loadData="loadData"
                @updateUrlEditModal="updateUrlEditModal"
              />
            </b-tab>
          </b-tabs>
          <!-- EDIT REEL FORM IF THERE ARE NO LANGUAGES AVAILABLE-->
          <reels-edit
            v-else
            :language="base"
            :base="base"
            :original-object="playReel"
            @editUploadReel="editUploadReel"
            @hideModal="hideModal"
            @loadData="loadData"
            @updateUrlEditModal="updateUrlEditModal"
          />
        </b-col>
        <b-col
          cols="4"
          class="d-flex justify-content-center"
        >
          <video
            id="demo"
            :key="playReel.key"
            class="video-js vjs-default-skin vjs-big-play-centered cursor-pointer border-reel"
            data-setup="{&quot;liveui&quot;: true}"
            controls
          >
            <source
              :src="playReel.fileUrl"
              type="video/mp4"
            >
          </video>
        </b-col>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BImg,
  BRow,
  BTab,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import localesLanguages from '@core/utils/languageModels'
import ViewFileModal from '../../common/ViewFileModal.vue'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
import ReelsListCard from './ReelsListCard.vue'
import ReelsEdit from './ReelsEdit.vue'

export default {
  components: {
    BButton,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BImg,
    BRow,
    BTab,
    BTabs,
    ReelsEdit,
    ReelsListCard,
    ViewFileModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      all: false,
      base: '',
      currentPage: 1,
      chosenReels: [],
      files: null,
      hasMore: true,
      headers: null,
      isLoading: false,
      languageService: true,
      locales: [],
      nReel: 0,
      optionsStatus: [
        { value: null, text: this.$t('editContent.status') },
        { value: 'RED', text: `🔴 ${this.$t('dataGeneric.inactive')}` },
        { value: 'GRE', text: `🟢 ${this.$t('dataGeneric.active')}` },
      ],
      perPage: 18,
      playReel: null,
      rows: 0,
      search: '',
      searchFiles: [],
      select: false,
      state: null,
      tabIndex: 0,
      userData: getUserData(),
    }
  },
  watch: {
    select() {
      if (!this.select) {
        this.chosenReels = []
        this.all = false
        this.state = null
      }
    },
  },
  mounted() {
    this.getHeaders()
    this.loadData()
    this.getClient()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    askDeleteReel(idReel) {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('code.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteReel(idReel)
        }
      })
    },
    deleteMultiple() {
      this.$swal({
        title: this.$t('dataGeneric.atention'),
        text: this.$t('code.title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('next'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let query = 'mutation {'
          this.chosenReels.forEach((element, i) => {
            query += `
              a_${i + 1}: deleteReel(id:"${element}") {
                found
                deletedId
              }
            `
          })
          query += '}'
          axios
            .post('', { query })
            .then(response => {
              messageError(response, this)
              showToast(this.$t('deleteSuccess'), 1, this)
              this.select = false
              this.all = false
              this.loadData()
            })
            .catch(res => {
              // eslint-disable-next-line
              console.log(res)
            })
        }
      })
    },
    deleteReel(idReel, search) {
      axios
        .post('', {
          variables: {
            id: idReel,
          },
          query: `
            mutation ($id: ID!) {
              deleteReel(id:$id) {
                found
                deletedId
              }
            }
          `,
        }).then(response => {
          messageError(response, this)
          if (response.data.data.deleteReel.found) {
            showToast(this.$t('reels.deleteSuccess'), 1, this)
            if (!search) this.loadData()
          } else {
            showToast(this.$t('reels.deleteError'), 2, this)
          }
        }).catch(res => {
          // eslint-disable-next-line
          console.log(res)
        })
    },
    downloadReel(file) {
      const xhr = new XMLHttpRequest()
      xhr.open('GET', file.fileUrl, true)
      xhr.responseType = 'blob'

      xhr.onload = function load() {
        const urlCreator = window.URL || window.webkitURL
        const videoUrl = urlCreator.createObjectURL(this.response)
        const tag = document.createElement('a')
        tag.href = videoUrl
        tag.download = file.name
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.onerror = err => {
        showToast(this.$t('reels.downloadError'), 2, this)
        // eslint-disable-next-line
        console.log(err)
      }
      xhr.send()
    },
    editUploadReel(file) {
      const index = this.searchFiles.findIndex(element => element.node.id === file.id)
      const editedFile = {
        node: {
          id: file.id,
          name: file.name,
          createdAt: file.createdAt,
          fileUrl: file.videoUrl,
          numViews: file.numViews,
          isActive: file.isActive,
          description: file.description,
          mediaLocation: file.mediaLocation,
        },
      }
      this.searchFiles.splice(index, 1, editedFile)
    },
    fetchData() {
      this.select = false
      const { headers } = this
      this.isLoading = true

      axios
        .post('', {
          variables: {
            first: this.perPage,
            offset: this.perPage * (this.currentPage - 1),
            name: this.search,
            client: this.userData.profile.client.id,
          },
          query: `
            query ($first: Int, $offset:Int, $name: String, $client: ID) {
              allReel(
                first: $first,
                offset: $offset,
                name: $name,
                client: $client
              ) {
                totalCount,
                pageInfo {
                  hasNextPage
                }
                edges {
                  node {
                    id
                    name
                    description
                    createdAt
                    numViews
                    isActive
                    mediaLocation
                    videoUrl
                  }
                }
              }
            }
          `,
        }, { headers })
        .then(response => {
          messageError(response, this)

          this.files = response.data.data.allReel
            ? response.data.data.allReel.edges
            : []

          this.rows = response.data.data.allReel.totalCount

          /*
          this conversion is needed for the component ViewFileModal,
          which expects the property fileUrl instead of videoUrl
          */
          const searchResults = this.files.map(element => ({
            node: {
              id: element.node.id,
              name: element.node.name,
              createdAt: element.node.createdAt,
              fileUrl: element.node.videoUrl,
              numViews: element.node.numViews,
              isActive: element.node.isActive,
              description: element.node.description,
              mediaLocation: element.node.mediaLocation,
            },
          }))
          if (response.data.data.allReel.pageInfo.hasNextPage) {
            this.searchFiles = [...this.searchFiles, ...searchResults]
            this.currentPage += 1
          } else {
            this.searchFiles = [...this.searchFiles, ...searchResults]
            this.hasMore = false
          }
          this.isLoading = false
        })
        .catch(res => {
          // eslint-disable-next-line
          console.log(res)
        })
    },
    getClient() {
      axios
        .post('', {
          variables: {
            client: this.userData.profile.client.id,
          },
          query: `
            query ($client: ID!) {
              client(id:$client){
                  defaultLanguage
                  availableLanguages
              }
            }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client
          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    getHeaders() {
      let defaultLang = 'es'
      try {
        defaultLang = this.userData.profile.client.defaultLanguage
      } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      }
      this.headers = {
        'Accept-Language': defaultLang,
        'Content-Language': defaultLang,
      }
    },
    getLocaleFormat(languagesString) {
      const languages = languagesString.split('|')

      const languagesFormatted = languages.map(language => {
        const parts = language.split('-')
        const languageFormatted = parts
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)).join('')
        return languageFormatted
      })
      return languagesFormatted
    },
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.fetchData()
      }
    },
    hideModal(modal) {
      this.$refs[modal].hide()
      this.playReel = null
    },
    loadData() {
      this.currentPage = 1
      this.hasMore = true
      this.searchFiles = []
      this.fetchData()
      this.totalCount()
    },
    redirectAddReel() {
      this.$router.push({ name: 'contents-reels-add' })
    },
    searching() {
      this.currentPage = 1
      this.searchFiles = []
      this.hasMore = true
      this.fetchData()
    },
    setChosenReels(data) {
      this.chosenReels = data
    },
    showModal(modal, reel) {
      this.playReel = reel
      this.playReel.key = Math.random().toString(36).substring(7)
      this.$nextTick(() => {
        this.$refs[modal].show()
      })
    },
    totalCount() {
      axios
        .post('', {
          variables: {
            client: this.userData.profile.client.id,
          },
          query: `
            query ($client:ID) {
              allReel (client:$client) {
                totalCount
              }
            }
          `,
        })
        .then(result => {
          messageError(result, this)
          this.nReel = result.data.data.allReel.totalCount
        })
    },
    updateReelStatus(item, newStatus) {
      axios
        .post('', {
          variables: {
            id: item.id,
            isActive: newStatus || !item.isActive,
          },
          query: `
            mutation ($id: ID!, $isActive: Boolean) {
              updateReel(id:$id, input:{isActive:$isActive}) {
                reel{
                  id,
                  isActive
                }
              }
            }      
          `,
        }).then(response => {
          messageError(response, this)
          if (response.data.data.updateReel.reel.isActive !== item.isActive) {
            const reelUpdateIndex = this.searchFiles.findIndex(reel => reel.node.id === item.id)
            if (reelUpdateIndex !== -1) {
              this.searchFiles[reelUpdateIndex].node.isActive = response.data.data.updateReel.reel.isActive
            }
          }
        }).catch(res => {
          // eslint-disable-next-line
          console.log(res)
        })
    },
    updateReelStatusMultiple() {
      let isActive = false
      let status = this.$t('dataGeneric.inactive')
      if (this.state === 'GRE') {
        isActive = true
        status = this.$t('dataGeneric.active')
      }

      let query = 'mutation ($isActive: Boolean) {'
      this.chosenReels.forEach((element, i) => {
        query += `
          a_${i + 1}: updateReel(id:"${element}", input:{isActive:$isActive}) {
            reel{
              id,
              isActive
            }
          }
        `
      })
      query += '}'
      axios
        .post('', {
          query,
          variables: {
            isActive,
          },
        })
        .then(response => {
          messageError(response, this)
          showToast(this.chosenReels.length === 1
            ? `${this.$t('reels.statusReelUpdated')} ${status}`
            : `${this.$t('reels.statusReelsUpdated')} ${status}`,
          1, this)
          this.chosenReels.forEach(element => {
            const reelUpdateIndex = this.searchFiles.findIndex(reel => reel.node.id === element)
            if (reelUpdateIndex !== -1) {
              this.searchFiles[reelUpdateIndex].node.isActive = isActive
            }
          })
          this.select = false
          this.all = false
        })
        .catch(res => {
          // eslint-disable-next-line
          console.log(res)
        })
    },
    updateUrlEditModal(newUrl) {
      this.playReel.key = Math.random().toString(36).substring(7)
      this.playReel.fileUrl = newUrl
    },
  },
}
</script>

<style scoped>
  .border-reel {
    height: auto;
    border: 1px solid white;
    border-radius: 10px;
  }
</style>
